<template>
  <!-- ACTION HISTORY MODAL SIDEBAR -->
  <div
    @click="$emit('overlayClicked')"
    class="modal-background-overlay2 h-16"
    :class="{ hidden: !isVisible }"
  ></div>
  <div
    class="modal-background-overlay"
    @click="$emit('overlayClicked')"
    :class="{ hidden: !isVisible }"
  ></div>
  <div
    class="modal-background-overlay3"
    @click="$emit('overlayClicked')"
    :class="{
      'modal-background-overlay--hidden': !isVisible,
      'modal-background-overlay--black': isVisible,
    }"
  ></div>
  <div
    style="z-index: 998"
    :class="{ 'modal-visible': isVisible }"
    class="
      modal
      bottom-0
      pb-12
      pt-28
      px-8
      overflow-y-auto
      bg-white
      w-1/3
      text-black
      flex flex-col
      z-50
      h-full
      fixed
    "
  >
    <div class="text-left z-50">
      <h1 class="text-xl font-bold">Add Customer</h1>

      <div class="leading-loose">
        <form @submit.prevent class="mt-4 bg-white">
          <div class="inline-block mt-2 w-full pr-1">
            <select
              class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
              v-model="newCustomer.customerCompany"
            >
              <option value="" disabled>Select Company</option>
              <option
                v-for="company in customerCompanies"
                :key="company._id"
                :value="company._id"
              >
                {{ company.name }}
              </option>
            </select>
          </div>
          <div class="inline-block mt-2 w-full pr-1">
            <!-- <input
              :class="{
                'outline-none border border-red-500': !validCustomerId,
              }"
              class=""
              name="actiontitle"
              type="text"
              placeholder="Customer ID"
              v-model="newCustomer.customerId"
            /> -->
            <div
              class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
            >
              {{ newCustomerId }}
            </div>
          </div>

          <!-- <div class="inline-block mt-2 w-full pr-1">
            <input
              class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
              name="actiontitle"
              type="text"
              placeholder="Customer"
              v-model="newCustomer.customer"
            />
          </div> -->

          <div class="inline-block mt-2 w-full pr-1">
            <input
              class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
              name="actiontitle"
              type="text"
              placeholder="Name"
              v-model="newCustomer.contactName"
            />
          </div>

          <div class="inline-block mt-2 w-full pr-1">
            <input
              class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
              name="actiontitle"
              type="text"
              placeholder="Email"
              v-model="newCustomer.contactEmail"
            />
          </div>

          <div class="inline-block mt-2 w-full pr-1">
            <input
              class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
              name="actiontitle"
              type="text"
              placeholder="Phone"
              v-model="newCustomer.contactMobile"
            />
          </div>

          <div class="inline-block mt-2 w-full pr-1">
            <input
              class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
              name="actiontitle"
              type="text"
              placeholder="Address"
              v-model="newCustomer.address"
            />
          </div>

          <h3 class="mt-6 text-lg font-bold">Login for Customer Portal</h3>

          <div class="inline-block mt-2 w-full pr-1">
            <input
              class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
              :class="{ 'border border-red-300': !passwordMatches }"
              name="actiontitle"
              type="password"
              placeholder="Password"
              v-model="newCustomer.password"
            />
          </div>

          <div class="inline-block mt-2 w-full pr-1">
            <input
              class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
              :class="{ 'border border-red-300': !passwordMatches }"
              name="actiontitle"
              type="password"
              placeholder="Confirm Password"
              v-model="newCustomer.confirm_password"
            />
          </div>

          <p class="text-sm text-yellow-500 mt-4">
            If password is not entered, customer will not be able to login.
          </p>

          <div class="mt-4">
            <button
              class="
                px-4
                mr-2
                py-2
                text-black
                font-light
                text-sm
                tracking-wider
                bg-gray-100
                hover:bg-gray-200
                rounded
              "
              @click.prevent="$emit('overlayClicked')"
            >
              Cancel
            </button>
            <button
              class="
                px-4
                py-2
                text-sm text-white
                font-light
                tracking-wider
                bg-gray-900
                hover:bg-gray-700
                rounded
              "
              :disabled="!canSave"
              :class="{
                'cursor-not-allowed': !canSave,
              }"
              type="submit"
              @click.prevent="addCustomer"
            >
              Add
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- INQUIRY MODAL SIDEBAR -->
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "NewCustomerModal",
  props: ["isVisible"],
  emits: ["overlayClicked", "customerAdded"],
  data() {
    return {
      newCustomer: {
        customerCompany: "",
        customer: "",
        contactName: "",
        contactEmail: "",
        contactMobile: "",
        address: "",
        password: "",
        confirm_password: "",
      },
    };
  },
  mounted() {
    this.getCustomerCompanies();
    this.getCustomers({ all: true });
  },
  methods: {
    ...mapActions(["getCustomerCompanies", "getCustomers"]),
    addCustomer() {
      this.$emit("customerAdded", {
        customerId: this.newCustomerId,
        ...this.newCustomer,
      });
      this.$emit("overlayClicked");
      this.newCustomer = {
        customerCompany: "",
        contactName: "",
        contactEmail: "",
        contactMobile: "",
        address: "",
        password: "",
        confirm_password: "",
      };
    },
    // resetForm() {
    //   this.newStatus = {
    //     title: "",
    //     date: "",
    //   };
    //   this.$emit("overlayClicked");
    // },
  },
  computed: {
    ...mapGetters(["customerCompanies", "allCustomers"]),
    newCustomerId() {
      this.newCustomer.customerCompany;
      if (!this.newCustomer.customerCompany) return "-";

      const company = this.customerCompanies.filter(
        (c) => c._id === this.newCustomer.customerCompany
      )[0];
      const companyId = company.code.replace(/[0-9]/g, "");

      function generateCustomerId(companyId, customers, baseNumber) {
        let number = baseNumber;

        let id = "";
        if (number < 10) {
          id = `${companyId}0${number}`;
        } else {
          id = `${companyId}${number}`;
        }

        return id;
      }

      let baseNumber = 0;
      let existingCustomerIndex;
      let id;
      do {
        baseNumber++;

        id = generateCustomerId(companyId, this.allCustomers, baseNumber);

        existingCustomerIndex = this.allCustomers.findIndex((c) => {
          return c.customerId === id;
        });
      } while (existingCustomerIndex !== -1);

      return id;
    },
    passwordMatches() {
      return this.newCustomer.password === this.newCustomer.confirm_password;
    },
    canSave() {
      return this.passwordMatches && this.newCustomer.customerCompany;
    },
  },
};
</script>

<style scoped>
.modal-background-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 997;
}

.modal-background-overlay2 {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1001;
}

.modal-background-overlay3 {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 49;
  transition: background 0.2s 0.3s, visibility 1s;
}

.modal-background-overlay--hidden {
  visibility: hidden;
}

.modal-background-overlay--black {
  background: rgba(0, 0, 0, 0.8);
  visibility: visible;
}

.modal {
  transition: transform 0.5s;
  right: 0;
  transform: translatex(100%);
}

.modal-visible {
  transform: translatex(0%);
}
</style>