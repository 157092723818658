<template>
  <!-- ACTION HISTORY MODAL SIDEBAR -->
  <div
    @click="$emit('overlayClicked')"
    class="modal-background-overlay2 h-16"
    :class="{ hidden: !isVisible }"
  ></div>
  <div
    class="modal-background-overlay"
    @click="$emit('overlayClicked')"
    :class="{ hidden: !isVisible }"
  ></div>
  <div
    class="modal-background-overlay3"
    @click="$emit('overlayClicked')"
    :class="{
      'modal-background-overlay--hidden': !isVisible,
      'modal-background-overlay--black': isVisible,
    }"
  ></div>
  <div
    style="z-index: 998"
    :class="{ 'modal-visible': isVisible }"
    class="
      modal
      bottom-0
      pb-12
      pt-28
      px-8
      overflow-y-auto
      bg-white
      w-1/3
      text-black
      flex flex-col
      z-50
      h-full
      fixed
    "
  >
    <div class="text-left z-50 cursor-default">
      <h1 class="text-xl font-bold">Edit Customer</h1>

      <div class="leading-loose">
        <form @submit.prevent class="mt-4 bg-white">
          <div class="inline-block mt-2 w-full pr-1">
            <select
              class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
              v-model="selectedCustomer.customerCompany"
            >
              <option value="" disabled>Select Company</option>
              <option
                v-for="company in customerCompanies"
                :key="company._id"
                :value="company._id"
              >
                {{ company.name }}
              </option>
            </select>
          </div>
          <div class="inline-block mt-2 w-full pr-1">
            <div
              class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
            >
              {{ selectedCustomer.customerId }}
            </div>
          </div>
          <div v-if="newCustomerId" class="inline-block mt-2 w-full pr-1">
            <div class="text-sm">
              <span class="font-bold text-red-500">NEW CUST. ID: </span>
              {{ newCustomerId }}
            </div>
          </div>
          <!-- <div class="inline-block mt-2 w-full pr-1">
            <input
              class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
              name="actiontitle"
              type="text"
              placeholder="Customer"
              v-model="selectedCustomer.customer"
            />
          </div> -->

          <div class="inline-block mt-2 w-full pr-1">
            <input
              class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
              name="actiontitle"
              type="text"
              placeholder="Name"
              v-model="selectedCustomer.contactName"
            />
          </div>

          <div class="inline-block mt-2 w-full pr-1">
            <input
              class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
              name="actiontitle"
              type="text"
              placeholder="Email"
              v-model="selectedCustomer.contactEmail"
            />
          </div>

          <div class="inline-block mt-2 w-full pr-1">
            <input
              class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
              name="actiontitle"
              type="text"
              placeholder="Phone"
              v-model="selectedCustomer.contactMobile"
            />
          </div>

          <div class="inline-block mt-2 w-full pr-1">
            <input
              class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
              name="actiontitle"
              type="text"
              placeholder="Address"
              v-model="selectedCustomer.address"
            />
          </div>

          <h3 class="mt-6 text-lg font-bold">Login for Customer Portal</h3>

          <div class="inline-block mt-2 w-full pr-1">
            <button
              @click.prevent="
                sendCustomerPasswordReset(selectedCustomer),
                  $emit('overlayClicked')
              "
              class="
                w-full
                px-2
                py-2
                text-sm text-gray-700
                bg-yellow-200
                rounded
              "
              name="actiontitle"
              type="button"
            >
              Send Password Reset Link
            </button>
          </div>

          <p
            class="text-sm text-yellow-500 mt-4"
            v-if="!selectedCustomer.loginCreated"
          >
            Customer login not created
          </p>

          <p
            class="text-sm text-green-500 mt-4"
            v-if="selectedCustomer.loginCreated"
          >
            Customer login created
          </p>

          <div class="mt-4">
            <button
              class="
                px-4
                mr-2
                py-2
                text-black
                font-light
                text-sm
                tracking-wider
                bg-gray-100
                hover:bg-gray-200
                rounded
              "
              @click.prevent="$emit('overlayClicked')"
            >
              Cancel
            </button>
            <button
              class="
                px-4
                py-2
                text-sm text-white
                font-light
                tracking-wider
                bg-gray-900
                hover:bg-gray-700
                rounded
              "
              type="submit"
              :disabled="!canSave"
              :class="{ 'cursor-not-allowed': !canSave }"
              @click.prevent="editCustomer"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- INQUIRY MODAL SIDEBAR -->
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "EditCustomerModal",
  props: ["isVisible", "customer"],
  emits: ["overlayClicked", "customerEdited"],
  data() {
    return {
      selectedCustomer: {
        customerCompany: "",
        customerId: "",
        customer: "",
        contactName: "",
        contactEmail: "",
        contactMobile: "",
        address: "",
        password: "",
        confirm_password: "",
      },
    };
  },
  methods: {
    ...mapActions(["sendCustomerPasswordReset"]),
    editCustomer() {
      const customer = JSON.parse(JSON.stringify(this.selectedCustomer));

      if (this.newCustomerId !== "") customer.customerId = this.newCustomerId;

      this.$emit("customerEdited", customer);
      this.$emit("overlayClicked");
    },
    // resetForm() {
    //   this.newStatus = {
    //     title: "",
    //     date: "",
    //   };
    //   this.$emit("overlayClicked");
    // },
  },
  computed: {
    ...mapGetters(["customerCompanies", "allCustomers"]),
    newCustomerId() {
      this.selectedCustomer.customerCompany;
      if (!this.selectedCustomer.customerCompany) return "";

      const company = this.customerCompanies.filter(
        (c) => c._id === this.selectedCustomer.customerCompany
      )[0];
      const companyId = company.code.replace(/[0-9]/g, "");

      const currentCustomerCompanyCode =
        this.selectedCustomer.customerId.replace(/[0-9]/g, "");

      if (currentCustomerCompanyCode === companyId) return "";

      function generateCustomerId(companyId, allCustomers, baseNumber) {
        let number = baseNumber;

        let id = "";
        if (number < 10) {
          id = `${companyId}0${number}`;
        } else {
          id = `${companyId}${number}`;
        }

        return id;
      }

      let baseNumber = 0;
      let existingCustomerIndex;
      let id;
      do {
        baseNumber++;

        id = generateCustomerId(companyId, this.allCustomers, baseNumber);

        existingCustomerIndex = this.allCustomers.findIndex((c) => {
          return c.customerId === id;
        });
      } while (existingCustomerIndex !== -1);

      return id;
    },
    passwordMatches() {
      return (
        this.selectedCustomer.password ===
        this.selectedCustomer.confirm_password
      );
    },
    canSave() {
      return this.passwordMatches && this.selectedCustomer.customerCompany;
    },
  },
  mounted() {
    this.selectedCustomer = JSON.parse(JSON.stringify(this.customer));

    if (this.selectedCustomer.customerCompany) {
      this.selectedCustomer.customerCompany = this.customer.customerCompany._id;
    } else {
      this.selectedCustomer.customerCompany = "";
    }

    if (!this.customer.loginCreated) this.selectedCustomer.loginCreated = false;
  },
  watch: {
    customer: {
      handler(customer) {
        this.selectedCustomer = JSON.parse(JSON.stringify(customer));

        if (this.selectedCustomer.customerCompany) {
          this.selectedCustomer.customerCompany = customer.customerCompany._id;
        } else {
          this.selectedCustomer.customerCompany = "";
        }

        if (!this.customer.loginCreated)
          this.selectedCustomer.loginCreated = false;
      },
    },
  },
};
</script>

<style scoped>
.modal-background-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 997;
}

.modal-background-overlay2 {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1001;
}

.modal-background-overlay3 {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 49;
  transition: background 0.2s 0.3s, visibility 1s;
}

.modal-background-overlay--hidden {
  visibility: hidden;
}

.modal-background-overlay--black {
  background: rgba(0, 0, 0, 0.8);
  visibility: visible;
}

.modal {
  transition: transform 0.5s;
  right: 0;
  transform: translatex(100%);
}

.modal-visible {
  transform: translatex(0%);
}
</style>